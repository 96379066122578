import { useNavigate } from "react-router-dom";
import AddCircle from "../img/add-circle.svg";
import Slideshow from "../components/Slideshow";

const Home = () => {
  const navigate = useNavigate();
  //Google tag manager
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({ lang: "en/sv/fi etc" });
  //End Google tag manager

  return (
    <main>
      <section className="Home Section Section--app">
        <div className="Section-background">
          <Slideshow />
        </div>
        <div className="Block Block--centeredStart">
          <h1 className="Block-title">Wood it</h1>
          <button className="Button--start" onClick={() => navigate("/upload")}>
            <img src={AddCircle} alt={""} />
          </button>
          <div>
            <p className="Block-text Block-text--start">
              Turn any building into wood
              <br />
              with the click of a button.
            </p>
          </div>
        </div>
        <a
          className="Button-bigArrowWrapper --absolute"
          href={"/profile?gallery=explore"}
        >
          <p>Go to gallery</p>
          <span className="Button-bigArrow"></span>
        </a>
      </section>
    </main>
  );
};

export default Home;
