import { useCallback, useEffect, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { Point } from "framer-motion";
import getCroppedImg from "../utils/cropperUtils";
import { useImageOrientation } from "../contexts/ImageOrientationContext";
import classNames from "classnames";

export interface CropperScreenDimensions {
  width: number;
  top: number;
}

interface ImageCropperProps {
  originalImageUrl: string;
  onCroppedImage: (
    image: string,
    cropperScreenDimensions: CropperScreenDimensions
  ) => void;
}

const ImageCropper = ({
  originalImageUrl,
  onCroppedImage,
}: ImageCropperProps) => {
  const { isPortrait } = useImageOrientation();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const getCropperScreenDimensions = () => {
    const rect = document
      .querySelector(".reactEasyCrop_CropArea")
      ?.getBoundingClientRect();
    const dimensions: CropperScreenDimensions = {
      width: rect ? rect.width : 0,
      top: rect ? rect.top : 0,
    };
    return dimensions;
  };

  const getCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        originalImageUrl,
        croppedAreaPixels,
        isPortrait
      );
      console.log("done", { croppedImage });
      onCroppedImage(croppedImage, getCropperScreenDimensions());
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <div
      className={classNames("Image-Cropper", { "--is-portrait": isPortrait })}
    >
      <div className="crop-container">
        <Cropper
          image={originalImageUrl}
          crop={crop}
          zoom={zoom}
          aspect={isPortrait ? 4 / 5 : 16 / 9}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      <div className="controls">
        {/* <input
          style={{ position: "absolute" }}
          type="range"
          name=""
          id=""
          min={1}
          max={3}
          step={0.1}
          value={zoom}
          onChange={(e) => {
            setZoom(Number(e.target.value));
          }}
        /> */}
        <div className="Navigation-progressWrap">
          <button
            className="Navigation-progressButton"
            onClick={getCroppedImage}
          >
            <div className="Button-bigArrowWrapper Button-bigArrowWrapper--yellowText">
              <p>Crop</p>
              <span className="Button-bigArrow"></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
