import Layout from "../components/Layout";

const About = () => {
    return (
        <Layout>
            <h1>About Page</h1>
            <p>This is the About Page.</p>
        </Layout>

    );
};

export default About;