import { faImages } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddImageIcon from "../img/add-image-icon.svg";
import React, { useEffect, useRef } from "react";

interface ImageUploadProps {
  onUploadedPhoto: (imageUrl: string) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onUploadedPhoto }) => {
  const labelRef = useRef<HTMLLabelElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result as string;
        onUploadedPhoto(imageUrl); // Pass the uploaded image URL to the parent component
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (labelRef.current) {
      labelRef.current.click();
    }
  }, []);

  return (
    <>
      <div
        className="Upload-uploadArea"
        onClick={() => labelRef.current?.click()}
      >
        {/* <FontAwesomeIcon icon={faImages} size="3x" color="#bbb" /> */}

        <img className="Upload-addImageicon" src={AddImageIcon} alt="" />
        <span>Choose an image</span>
      </div>
      <div className="Navigation-progressWrap">
        <label
          className="Navigation-progressButton"
          ref={labelRef}
          htmlFor="imageUpload"
        >
          {/* <i className="Navigation-progressIconWrap">
            <FontAwesomeIcon icon={faImages} size="2x" />
          </i> */}
          <input
            className="Block-fileUpload"
            name="imageUpload"
            id="imageUpload"
            type="file"
            accept="capture=camera,image/*"
            onChange={handleFileChange}
          />
          {/* <span>Add</span> */}
        </label>
      </div>
    </>
  );
};

export default ImageUpload;
