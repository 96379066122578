import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function Footer() {
  return (
    <footer className="Footer">
      <div className="Footer-main">
        <a className="Footer-logoLink" href="https://www.storaenso.com/">
          <img src="/logo-full.png" alt="Stora Enso Coloured Logotype" />
        </a>
        <p className="Footer-text">
          Part of the global bioeconomy, Stora Enso is a leading provider of
          renewable products in packaging, biomaterials and wooden construction,
          and one of the largest private forest owners in the world. We believe
          that everything that is made from fossil-based materials today can be
          made from a tree tomorrow. Stora Enso has approximately 21,000
          employees and our sales in 2022 were EUR 11.7 billion. Stora Enso
          shares are listed on Nasdaq Helsinki Oy (STEAV, STERV) and Nasdaq
          Stockholm AB (STE A, STE R). In addition, the shares are traded in the
          USA as ADRs (SEOAY).
        </p>
        <div className="Footer-socialMedia">
          <a
            id="youtube"
            className="Footer-socialMediaLink"
            href="https://www.youtube.com/storaenso"
            target="_blank"
          >
            <YoutubeSvg />
          </a>
          <a
            id="facebook"
            className="Footer-socialMediaLink"
            href="https://facebook.com/storaenso"
            target="_blank"
          >
            <FacebookSvg />
          </a>
          <a
            id="twitter"
            className="Footer-socialMediaLink"
            href="https://twitter.com/storaenso"
            target="_blank"
          >
            <TwitterSvg />
          </a>
          <a
            id="linkedin"
            className="Footer-socialMediaLink"
            href="https://linkedin.com/company/stora-enso"
            target="_blank"
          >
            <LinkedinSvg />
          </a>
          <a
            id="instagram"
            className="Footer-socialMediaLink"
            href="https://instagram.com/storaensoworld"
            target="_blank"
          >
            <InstagramSvg />
          </a>
          <a
            id="pinterest"
            className="Footer-socialMediaLink"
            href="https://pinterest.com/storaensoworld/"
            target="_blank"
          >
            <PinterestSvg />
          </a>
        </div>
      </div>
      <div className="Footer-privacy">
        <a
          className="Footer-privacyLink"
          href="https://www.storaenso.com/en/privacy"
          target="_blank"
        >
          Privacy
        </a>
        <a
          className="Footer-privacyLink"
          href="https://www.storaenso.com/en/legal-notice"
          target="_blank"
        >
          Legal notice
        </a>
        <a
          className="Footer-privacyLink"
          href="https://www.storaenso.com/-/media/documents/download-center/documents/sustainability/msa/se_slavery_and_human_trafficking_statement_2022.pdf"
          target="_blank"
        >
          MSA Report
        </a>
        <a
          className="Footer-privacyLink"
          href="https://www.storaenso.com/en/sustainability/code-of-conduct"
          target="_blank"
        >
          Speak Up Hotline
        </a>
      </div>
    </footer>
  );
}

const YoutubeSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10">
      <path d="M12.94 5.04c0 1.047-.128 2.095-.128 2.095s-.126.89-.515 1.284c-.49.514-1.043.517-1.296.547-1.81.13-4.53.135-4.53.135s-3.364-.03-4.4-.13c-.287-.054-.934-.038-1.426-.553C.256 8.025.13 7.134.13 7.134S0 6.087 0 5.04v-.982C0 3.01.13 1.964.13 1.964S.255 1.072.643.68C1.136.162 1.687.16 1.94.13 3.75 0 6.468 0 6.468 0h.005S9.19 0 11 .13c.254.03.806.033 1.297.55.39.392.515 1.284.515 1.284s.13 1.046.13 2.094v.982zM5.136 6.23 8.63 4.418 5.135 2.593V6.23z" />
    </svg>
  );
};
const FacebookSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="10"
      viewBox="0 0 6 12"
    >
      <path d="M5.876 1.878H4.81c-.835 0-.997.398-.997.98v1.284h1.992l-.26 2.012H3.814v5.16H1.737v-5.16H0V4.142h1.737V2.659C1.737.937 2.788 0 4.324 0c.735 0 1.367.054 1.552.08v1.798Z" />
    </svg>
  );
};
const TwitterSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10">
      <path d="M10.156 2.29c.004.1.007.2.007.301 0 3.067-2.335 6.604-6.605 6.604A6.572 6.572 0 0 1 0 8.153a4.656 4.656 0 0 0 3.437-.962 2.323 2.323 0 0 1-2.17-1.612 2.342 2.342 0 0 0 1.05-.04A2.322 2.322 0 0 1 .455 3.263v-.03a2.31 2.31 0 0 0 1.051.291A2.321 2.321 0 0 1 .788.425a6.59 6.59 0 0 0 4.784 2.426A2.322 2.322 0 0 1 9.527.734 4.645 4.645 0 0 0 11.002.17a2.333 2.333 0 0 1-1.02 1.285 4.652 4.652 0 0 0 1.332-.366 4.725 4.725 0 0 1-1.158 1.201" />
    </svg>
  );
};
const LinkedinSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13">
      <path d="M11.507 12.082H9.163V8.414c0-.875-.015-2-1.218-2-1.22 0-1.406.953-1.406 1.937v3.731H4.197V4.54h2.247v1.03h.033c.312-.593 1.077-1.219 2.218-1.219 2.374 0 2.812 1.562 2.812 3.594v4.137ZM1.553 3.508A1.36 1.36 0 1 1 1.552.79a1.36 1.36 0 0 1 .001 2.72Zm1.173 8.574H.379V4.54h2.347z" />
    </svg>
  );
};
const InstagramSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 14 14"
    >
      <path d="M6.616.004c-1.797 0-2.022.008-2.728.04-.704.032-1.185.144-1.606.308a3.242 3.242 0 0 0-1.172.763 3.243 3.243 0 0 0-.763 1.172c-.163.42-.275.901-.307 1.606-.032.705-.04.93-.04 2.727 0 1.797.008 2.023.04 2.728.032.705.144 1.186.307 1.606.17.435.396.804.763 1.172.368.368.737.594 1.172.763.421.164.902.276 1.606.308.706.032.931.04 2.728.04 1.797 0 2.022-.008 2.728-.04.704-.032 1.185-.144 1.606-.308a3.242 3.242 0 0 0 1.172-.763c.368-.368.594-.737.763-1.172.164-.42.276-.901.308-1.606.032-.705.04-.93.04-2.728 0-1.796-.008-2.022-.04-2.727-.032-.705-.144-1.186-.308-1.606a3.243 3.243 0 0 0-.763-1.172A3.243 3.243 0 0 0 10.95.352c-.42-.164-.902-.276-1.606-.308-.706-.032-.93-.04-2.728-.04Zm0 1.192c1.767 0 1.976.007 2.674.039.645.03.995.137 1.228.228.31.12.53.263.761.495.232.231.375.452.495.76.09.234.198.584.228 1.229.032.698.038.907.038 2.673 0 1.767-.006 1.976-.038 2.674-.03.645-.137.995-.228 1.229a2.05 2.05 0 0 1-.495.76 2.05 2.05 0 0 1-.76.495c-.234.09-.584.199-1.23.228-.697.032-.906.039-2.673.039-1.766 0-1.976-.007-2.673-.039-.645-.03-.996-.137-1.229-.228a2.05 2.05 0 0 1-.76-.495 2.05 2.05 0 0 1-.495-.76c-.091-.234-.199-.584-.228-1.229-.032-.698-.039-.907-.039-2.674 0-1.766.007-1.975.039-2.673.03-.645.137-.995.228-1.229.12-.308.263-.529.494-.76a2.05 2.05 0 0 1 .761-.495c.233-.09.584-.199 1.229-.228.697-.032.907-.039 2.673-.039Z" />
      <path d="M6.616 8.826a2.205 2.205 0 1 1 0-4.41 2.205 2.205 0 0 1 0 4.41Zm0-5.603a3.398 3.398 0 1 0 0 6.795 3.398 3.398 0 0 0 0-6.795Zm4.326-.134a.794.794 0 1 1-1.588 0 .794.794 0 0 1 1.588 0" />
    </svg>
  );
};
const PinterestSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16">
      <path
        d="M3.365 15.568c.4-.653 1-1.722 1.221-2.576l.612-2.335c.32.61 1.257 1.128 2.252 1.128 2.963 0 5.098-2.725 5.098-6.11C12.548 2.428 9.9 0 6.491 0 2.251 0 0 2.846 0 5.945 0 7.386.767 9.18 1.994 9.751c.186.087.286.049.329-.131.032-.137.198-.804.273-1.114a.294.294 0 0 0-.068-.282c-.406-.492-.732-1.398-.732-2.242 0-2.167 1.641-4.264 4.437-4.264 2.413 0 4.103 1.645 4.103 3.997 0 2.658-1.342 4.5-3.088 4.5-.965 0-1.686-.798-1.455-1.776.277-1.168.814-2.428.814-3.271 0-.755-.405-1.384-1.243-1.384-.986 0-1.778 1.02-1.778 2.386 0 .87.294 1.458.294 1.458s-.974 4.117-1.152 4.884c-.198.849-.12 2.044-.034 2.821 0 0 .091.625.219.663.15.046.4-.345.452-.428"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Footer;
