import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    // Scroll to top if path changes (e.g., when navigating between pages)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}