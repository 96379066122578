import React, { useState } from "react";
import { BASE } from "../constants";
import classNames from "classnames";
import Register from "./Register";

interface LoginProps {
  onLogin: (loginData: any) => void;
}

function Login({ onLogin }: LoginProps) {
  const [isWorking, setIsWorking] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);

  const handleLogin = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsWorking(true);

    const target = event.target as HTMLFormElement;

    const formData = new FormData(target);
    const username = formData.get("username");
    const password = formData.get("password");

    const res = await fetch(`${BASE}/api/admin/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    try {
      const data = await res.json();
      if (res.status == 200) {
        onLogin({
          status: "success",
          message: data.message,
          username: data.username,
        });
      } else {
        setShowErrorText(true);
      }

      setIsWorking(false);
    } catch (error) {
      console.error(error);
      setIsWorking(false);
    }
  };

  return (
    <div className="Admin-loginWrap">
      <h2>{isRegister ? "Register new account" : "Log in to Admin Panel"}</h2>
      {isRegister ? (
        <>
          <Register />
          <button onClick={() => setIsRegister(false)} className="u-underline">
            Back to login
          </button>
        </>
      ) : (
        <>
          <form
            className={classNames("Form", {
              "is-state--submitting": isWorking,
            })}
            onSubmit={handleLogin}
          >
            <div className="Form-fieldWrap">
              <label className="Form-label" htmlFor="username">
                Your username
              </label>
              <input
                className="Form-field"
                name="username"
                id="username"
                type="text"
                placeholder="Username"
                required
              />
            </div>
            <div className="Form-fieldWrap">
              <label className="Form-label" htmlFor="password">
                Your password
              </label>
              <input
                className="Form-field"
                name="password"
                id="password"
                type="password"
                placeholder="Password"
                required
              />
            </div>
            {showErrorText && (
              <p className="Form-label Admin-loginErrorText">
                Wrong username or password.
              </p>
            )}

            <button className="Button">
              {isWorking ? "Logging in..." : "Log in"}
            </button>
          </form>
          <button onClick={() => setIsRegister(true)} className="u-bold">
            Register new account
          </button>
        </>
      )}
    </div>
  );
}

export default Login;
