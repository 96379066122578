const Modal = ({ onModalEdgeClick }: any) => {
  return (
    <>
      <button onClick={onModalEdgeClick} className="Modal"></button>
      <div className="Modal-contentWrapper">
        <h2>
          Disclaimer: Artificial Intelligence-powered creative image generation
        </h2>
        <p>
          The AI image generator allows you to upload images and create new
          content using our AI technology that is based on Leonardo Ai. With
          uploading images you provide input to the AI image generator 
          (“Input”). After uploading you receive output generated and returned
          by the AI image generator based on the Input (“Output”).
          <br />
          <br />
          The AI image generator  will analyze the uploaded images and create
          new image that incorporates wood. While we strive for high-quality
          results, please be aware that AI-generated images are based on
          patterns and may not always perfectly align with your original intent.
          <br />
          <br />
          By using the AI image generator  and uploading Input you grant Stora
          Enso Oyj, Salmisaarenaukio 2, 00180 Helsinki, Finnland (“Stora Enso”)
          a non-exclusive, worldwide, irrevocable, perpetual royalty-free
          license to use, modify, and distribute the Input for promotional and
          marketing purposes. You expressly warrant that you have all rights and
          permission to any Inputs you upload. While you retain ownership of the
          original images you upload, all AI-generated Output is considered
          derivative works created by our the AI image generator. Therefore, all
          Output is and remains the property of Stora Enso
          <br />
          <br />
          We value your privacy. Any data you upload is subject to our{" "}
          <a
            href="https://www.storaenso.com/en/privacy"
            className="Modal-link"
            target="_blank"
            title="Privacy policy"
          >
            privacy policy <span></span>
          </a>{" "}
          and we will not share your personal information with third parties.
          However, as we retain ownership on the Output, the Output may be used
          by Stora Enso for marketing and advertising purposes. This includes -
          but is not limited to – publication in print media, digital media,
          social networks, exhibitions, presentations and other advertising
          materials.
          <br />
          <br />
          You will indemnify and hold Stora Enso harmless from any claims,
          damages, liabilities, demands, actions, losses, costs and expenses
          that arise from any violations of the Terms stated in this Disclaimer,
          including any claims related to intellectual property rights of 3rd
          parties with respect to you uploaded Input.
          <br />
          <br />
          If you have questions about our AI-powered image generation platform,
          its usage, or your rights, please contact us at
          data.privacy@storaenso.com
        </p>
      </div>
      <button onClick={onModalEdgeClick} className="Modal-closeButton u-button">
        Close
      </button>
    </>
  );
};

export default Modal;
