import About from "./About";
import Contact from "./Contact";
import Home from "./Home";
import Profile from "./Profile";
import Upload from "./Upload";
import Generation from "./Generation";
import Admin from "./Admin";
import Crop from "./Crop";
import SingleImage from "./SingleImage";
import Voila from "./Voila";

export interface RouteTypes {
  path: string;
  title: string;
  element: JSX.Element;
}

const routesData: RouteTypes[] = [
  {
    path: "/",
    title: "Hem",
    element: <Home />,
  },
  {
    path: "/om",
    title: "Om oss",
    element: <About />,
  },
  {
    path: "/kontakt",
    title: "Kontakta oss",
    element: <Contact />,
  },
  {
    path: "/upload",
    title: "Upload",
    element: <Upload />,
  },
  {
    path: "/generation",
    title: "Genererade bilder",
    element: <Generation />,
  },
  {
    path: "/profile",
    title: "Profile",
    element: <Profile />,
  },
  {
    path: "/admin",
    title: "Admin panel",
    element: <Admin />,
  },
  {
    path: "/crop",
    title: "Crop",
    element: <Crop />,
  },
  {
    path: "/single-image",
    title: "Single Image",
    element: <SingleImage />,
  },
  {
    path: "/voila",
    title: "Voila",
    element: <Voila />,
  },
];

export default routesData;
