import React, { useEffect, useState } from "react";
import { compressImage, getSessionTokenFromStorage } from "../lib/utils";
import { BASE } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faMagicWandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { useProgress } from "../contexts/ProgressContext";
import Modal from "./Modal";

export interface UploadResultTypes {
  status: string;
  message: string;
  initImageId?: string;
}
interface ImageSubmitProps {
  onSubmittedImage: (result: UploadResultTypes) => void;
  base64Image: string;
  canSubmit: boolean;
}

/* 
1. Post init image  
    i. Upload image to Leonardo
    ii. Get details about uploaded image from Leonardo
    iii. Save image with some details from Leonardo on server
*/

const ImageSubmit: React.FC<ImageSubmitProps> = ({
  onSubmittedImage,
  base64Image,
  canSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { progress, setProgress } = useProgress();
  const submitButtonRef = React.useRef<HTMLButtonElement>(null);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const postInitImage = async (imageSource: string, sessionToken: string) => {
    const res = await fetch(`${BASE}/api/images/init-image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionToken: sessionToken,
        imageData: imageSource.replace(
          /^data:image\/(png|jpeg);base64,/,
          "data:image/jpeg;base64,"
        ),
      }),
    });

    try {
      const data = await res.json();
      onSubmittedImage({
        status: "success",
        message: data.message,
        initImageId: data.initImageId,
      });
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error saving the image:", error);
      onSubmittedImage({ status: "error", message: (error as Error).message });
    }
  };

  const handleSubmitImage = async (base64Image: string) => {
    setIsSubmitting(true);

    // Options for image compression
    const imageSource = base64Image;
    const maxWidth = 2048;
    const maxHeight = 2048;

    const imagePromise = await compressImage(imageSource, maxWidth, maxHeight);

    try {
      const compressedBase64 = await Promise.resolve(imagePromise);
      const sessionToken = getSessionTokenFromStorage();

      if (!compressedBase64 || !sessionToken) {
        console.error("Missing compressed image or session token");
        return;
      }

      postInitImage(compressedBase64, sessionToken);
    } catch (error) {
      console.error("Error compressing the image:", error);
      onSubmittedImage({ status: "error", message: "Couldn't compress image" });
    }
  };

  return (
    <div className="Navigation-progressWrap">
      {modalIsOpen && (
        <Modal
          onModalEdgeClick={() => {
            setModalIsOpen(false);
          }}
        />
      )}
      <button
        className="Navigation-progressButton"
        ref={submitButtonRef}
        disabled={!hasConfirmed}
        onClick={() => handleSubmitImage(base64Image)}
      >
        {isSubmitting ? (
          <>
            <i className="Navigation-progressIconWrap">&hellip;</i>
            <span>Submitting</span>
          </>
        ) : (
          <div className="Button-bigArrowWrapper Button-bigArrowWrapper--yellowText">
            <p>Wood it!</p>
            <span className="Button-bigArrow"></span>
          </div>
        )}
      </button>

      <label className="Form-checkboxLabel" htmlFor="confirmImageRights">
        <input
          onChange={(e) => setHasConfirmed(e.target.checked)}
          className="Form-checkbox"
          name="confirmImageRights"
          id="confirmImageRights"
          type="checkbox"
        />
        <span>
          You agree to only upload images that exclusively depict buildings,
          landscapes, or non-personal objects. You represent and warrant that
          you are at least 18 years of age. By using the AI image generator you
          acknowledge and agree with our{" "}
          <button onClick={() => setModalIsOpen(true)}>Disclaimer</button>.
        </span>
      </label>
    </div>
  );
};

export default ImageSubmit;
