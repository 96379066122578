import LogoStoraEnso from "../img/stora-enso-logo-text.webp";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useProgress } from "../contexts/ProgressContext";
import { BASE } from "../constants";

export default function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showAbout, setShowAbout] = useState<boolean>(false);
  const [showSylva, setShowSylva] = useState<boolean>(false);

  const handleHamburgerClick = () => {
    document.body.classList.toggle("--menu-is-open");
    if (!document.body.classList.contains("--menu-is-open")) {
      setShowAbout(false);
      setShowSylva(false);
    }
  };

  return (
    <>
      {location.pathname !== "/admin" && (
        <nav className="Navigation">
          <Link to={"/"} id="home">
            <figure className="Navigation-logoWrapper">
              <img src={LogoStoraEnso} alt="" className="Navigation-logo" />
            </figure>
          </Link>
          <div className="Navigation-linksWrapper">
            {location.pathname != "/" && location.pathname != "/profile" && (
              <Link className={"u-button"} id="profile" to="/profile">
                <p>My gallery</p>
              </Link>
            )}

            <button
              className="Navigation-hamburgerWrapper"
              onClick={handleHamburgerClick}
            >
              <div className="Navigation-hamburgerBar --top"></div>
              <div className="Navigation-hamburgerBar --middle"></div>
              <div className="Navigation-hamburgerBar --bottom"></div>
            </button>
          </div>
          <button
            className="MainMenu-closeArea"
            onClick={handleHamburgerClick}
          ></button>
          <ul className="MainMenu">
            <li className="MainMenu-gridItem">
              <button
                className={showSylva || !showAbout ? "" : "--blured"}
                onClick={() => {
                  setShowAbout(!showAbout);
                  setShowSylva(false);
                }}
              >
                <span>About</span>
              </button>
            </li>
            <li className="MainMenu-gridItem">
              <button
                className={showAbout || !showSylva ? "" : "--blured"}
                onClick={() => {
                  setShowSylva(!showSylva);
                  setShowAbout(false);
                }}
              >
                <span>Sylva™</span>
              </button>
            </li>
            {showAbout && (
              <li className="MainMenu-gridItem MainMenu-gridItem--textArea">
                <a
                  href="https://www.storaenso.com/en/products/mass-timber-construction/sylva/sylva-talks"
                  target="_blank"
                >
                  Wood is a great building material; it stores carbon, and it
                  even grows back. We want the entire construction industry to
                  question conventional building methods, so the world becomes
                  more sustainable.
                  <br />
                  <br />
                  And this AI tool does exactly that by allowing you to see for
                  yourself, in the easiest way possible. Just snap a picture of
                  a building and watch it transform into wood!
                  <br />
                  <br />
                  Want to build the real deal? Learn more about Sylva, the easy
                  way of constructing with wood{" "}
                  <span
                    className="SingleImage-inlineArrow --yellow"
                    style={{ width: "0.75rem", height: "0.75rem" }}
                  ></span>
                </a>
              </li>
            )}
            {/* The first text below is the same as the one above but hidden. Used to maintain the same height position for the Sylva text. */}
            {showSylva && (
              <li className="MainMenu-gridItem MainMenu-gridItem--textArea">
                <a
                  href="https://www.storaenso.com/en/products/mass-timber-construction/sylva"
                  target="_blank"
                >
                  Sylva is Stora Enso’s range of prefabricated wood-based
                  products for low-carbon buildings. The Sylva kit includes
                  everything needed to create a modern, sustainable wood
                  structure. Our custom-made elements (Sylva Walls, Floors,
                  Roofs, Stairs, Beams and Columns), optimise the use of
                  sustainable wood to suit any application and requirement.
                  <br />
                  <br />
                  Read more about how to build easier in wood{" "}
                  <span
                    className="SingleImage-inlineArrow --yellow"
                    style={{
                      width: "0.75rem",
                      height: "0.75rem",
                    }}
                  ></span>
                </a>
              </li>
            )}
            {!showAbout && !showSylva && (
              <li className="MainMenu-gridItem MainMenu-gridItem--textArea"></li>
            )}
          </ul>
        </nav>
      )}
    </>
  );
}
