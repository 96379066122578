import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImageSubmit, { UploadResultTypes } from "../components/ImageSubmit";
import ImageUpload from "../components/ImageUpload";
import { useAlert } from "../contexts/AlertContext";
import { useProgress } from "../contexts/ProgressContext";
import { useImageOrientation } from "../contexts/ImageOrientationContext";
import ImageCropper, {
  CropperScreenDimensions,
} from "../components/ImageCropper";
import classNames from "classnames";

function Upload() {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [userHasApproved, setUserHasApproved] = useState<boolean>(false);
  const [cropperScreenDimensions, setCropperScreenDimensions] =
    useState<CropperScreenDimensions>(); //The width and top of the crop area in pixels.
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { setProgress } = useProgress();
  const { isPortrait, setIsPortrait } = useImageOrientation();

  /* EFFECTS
   */

  /* FUNCTIONS
   */

  const setImageOrientation = (imageUrl: string) => {
    let image = new Image();
    image.src = imageUrl;
    image.decode().then(() => {
      const ratio = image.width / image.height;
      if (ratio < 1) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    });
  };

  const handleUploadedImage = (imageUrl: string) => {
    setProgress("uploaded");
    setImageOrientation(imageUrl);
    setUploadedImage(imageUrl);
  };

  const handleSubmittedImage = (result: UploadResultTypes) => {
    if (result.status === "success") {
      navigate(`/generation?init=${result.initImageId}`);
    } else {
      console.error("Failed to submit image", result);
      showAlert("Something went wrong", "error");
    }
  };

  return (
    <section
      //"isCropper class does nothing now, but keeping the logic if needed later"
      className={`Section--viewImage ${
        uploadedImage && !croppedImage ? "isCropper" : ""
      }`}
    >
      {!uploadedImage && (
        <div>
          <ImageUpload onUploadedPhoto={handleUploadedImage} />
        </div>
      )}
      {uploadedImage && !croppedImage && (
        <>
          <button
            className="Button-arrowBackWrapper"
            onClick={() => {
              setUploadedImage("");
            }}
          >
            <div className="Button-bigArrowWrapper Button-bigArrowWrapper--yellowText">
              <span className="Button-bigArrow --turned"></span>
            </div>
          </button>
          <ImageCropper
            originalImageUrl={uploadedImage}
            onCroppedImage={(croppedImage, cropperScreenDimensions) => {
              setCroppedImage(croppedImage);
              setCropperScreenDimensions(cropperScreenDimensions);
            }}
          />
        </>
      )}
      {croppedImage && (
        <>
          <figure
            className={classNames("Section--viewImageImageWrapper ", {
              "--is-landscape": !isPortrait,
            })}
            style={{
              top: cropperScreenDimensions?.top,
              width: cropperScreenDimensions?.width,
              height: isPortrait
                ? cropperScreenDimensions?.width
                  ? cropperScreenDimensions.width / (4 / 5)
                  : 0
                : cropperScreenDimensions?.width
                ? cropperScreenDimensions.width / (16 / 9)
                : 0,
            }}
          >
            <img src={croppedImage} />
          </figure>

          <button
            className="Button-arrowBackWrapper"
            onClick={() => {
              setCroppedImage("");
            }}
          >
            <div className="Button-bigArrowWrapper Button-bigArrowWrapper--yellowText">
              <span className="Button-bigArrow --turned"></span>
            </div>
          </button>

          <ImageSubmit
            base64Image={croppedImage}
            onSubmittedImage={handleSubmittedImage}
            canSubmit={userHasApproved}
          />
        </>
      )}
    </section>
  );
}

export default Upload;
