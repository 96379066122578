import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import LoaderSpinner from "./LoaderSpinner";
import WoodModuleAnimation from "./WoodModuleAnimation";

function LoaderScreen() {
  const aiTexts = [
    "AI is working on your wooden masterpiece...",
    "AI is building your wooden dream...",
    "Busy turning buildings into wood...",
    "AI is working its magic...",
  ];

  const [currentAIIndex, setCurrentAIIndex] = useState<number>(
    Math.floor(Math.random() * aiTexts.length)
  );

  return (
    <AnimatePresence>
      <div className="LoaderScreen">
        <motion.div
          className="LoaderScreen-content"
          initial={{ height: 0 }}
          animate={{ height: "100%" }}
          exit={{ height: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <WoodModuleAnimation />
          <h2>{aiTexts[currentAIIndex]}</h2>
          {/* <AnimatePresence>
            <p>{currentWoodFact}</p>
          </AnimatePresence> */}
        </motion.div>
      </div>
    </AnimatePresence>
  );
}

export default LoaderScreen;
