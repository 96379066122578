import React, { useEffect, useRef, useState } from "react";
import WoodModuleImg01 from "../img/wood-module_01.svg";
import WoodModuleImg02 from "../img/wood-module_02.svg";
import WoodModuleImg03 from "../img/wood-module_03.svg";
import WoodModuleImg04 from "../img/wood-module_04.svg";
import { motion } from "framer-motion";

const images = [
  WoodModuleImg01,
  WoodModuleImg02,
  WoodModuleImg03,
  WoodModuleImg04,
];

const WoodModuleAnimation = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [interpolateValue, setInterpolateValue] = useState<number>(1);
  const imgRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setInterpolateValue(interpolateValue === 0 ? 1 : 0);
      },
      interpolateValue === 0 ? 300 : 900
    );

    setTimeout(() => {
      if (interpolateValue === 0) {
        if (currentImageIndex < images.length - 1) {
          setCurrentImageIndex(currentImageIndex + 1);
        } else {
          setCurrentImageIndex(0);
        }
      }
    }, 200);

    return () => clearInterval(interval);
  }, [interpolateValue]);

  return (
    <section className="WoodModuleAnimation">
      <figure>
        <motion.img
          animate={{ opacity: interpolateValue, scale: interpolateValue }}
          transition={{ type: "spring", bounce: 0.333 }}
          ref={imgRef}
          src={images[currentImageIndex]}
          alt=""
        />
      </figure>
    </section>
  );
};

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export default WoodModuleAnimation;
