import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
    title?: string;
    children?: ReactNode;
}


function Layout({ title, children }: Props) {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <main>
            <section className="Layout">
                {children}
            </section>
        </main>
    )
}

export default Layout;