import React, { ReactNode, createContext, useContext, useState } from "react";

interface ImageOriantationContextType {
  isPortrait: boolean;
  setIsPortrait: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImageOriantationContext = createContext<
  ImageOriantationContextType | undefined
>(undefined);

interface ImageOrientationProviderProps {
  children: React.ReactNode;
}

const ImageOrientationProvider: React.FC<ImageOrientationProviderProps> = ({
  children,
}) => {
  const [isPortrait, setIsPortrait] = useState<boolean>(false);

  return (
    <ImageOriantationContext.Provider value={{ isPortrait, setIsPortrait }}>
      {children}
    </ImageOriantationContext.Provider>
  );
};

const useImageOrientation = (): ImageOriantationContextType => {
  const context = useContext(ImageOriantationContext);
  if (!context) {
    throw new Error(
      "useImageOrientation must be used within a ImageOrientationProvider"
    );
  }
  return context;
};

export { ImageOrientationProvider, useImageOrientation };
