import { useEffect, useState } from "react";
import { BASE } from "../constants";

/** Set the session token in local storage */
export const setSessionTokenInStorage = (sessionToken: string) => {
  try {
    localStorage.setItem("sessionToken", sessionToken);
  } catch (error) {
    console.error("Error setting session token:", error);
  }
};

/** Retrieve the session token from local storage */
export const getSessionTokenFromStorage = () => {
  try {
    // Retrieve the session token from local storage
    const sessionToken = localStorage.getItem("sessionToken");

    if (sessionToken) {
      return sessionToken;
    }
  } catch (error) {
    console.error("Error retrieving session token:", error);
  }

  // Return null if session token is not found or an error occurs
  return false;
};

/** Get width and height of image in pixels */
export const getImageSize = async (img: HTMLImageElement) => {
  const maxDimension = 1024;
  const aspectRatio = img.naturalWidth / img.naturalHeight;

  // Leonardo wants width and height to be multiples of 8
  const multipleOfEight = (number: number) => {
    return Math.round(number / 8) * 8;
  };

  let width, height;

  if (aspectRatio >= 1) {
    width = Math.min(img.naturalWidth, maxDimension);
    height = width / aspectRatio;
  } else {
    height = Math.min(img.naturalHeight, maxDimension);
    width = height * aspectRatio;
  }

  return {
    width: Math.floor(multipleOfEight(width)).toString(),
    height: Math.floor(multipleOfEight(height)).toString(),
  };
};

export const fetchGenerationId = async (
  initImageId: string,
  width: string,
  height: string
) => {
  const res = await fetch(`${BASE}/api/leonardo/generation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      initImageId: initImageId,
      width: width,
      height: height,
    }),
  });

  if (!res.ok) {
    console.error("Failed to fetch generation ID", res);
    // TODO: Handle error
    return false;
  }

  try {
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Couldn't resolve generation response", error);
    // TODO: Handle error
    return false;
  }
};

export const fetchGenerationImages = async (generationId: string) => {
  const res = await fetch(`${BASE}/api/leonardo/generation/${generationId}`);

  if (!res.ok) {
    console.error("Failed to fetch generation images", res);
    // TODO: Handle error
    return false;
  }

  try {
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Couldn't resolve generation images", error);
    // TODO: Handle error
    return false;
  }
};

// Function to compress and resize an image
export const compressImage = (
  base64Image: string,
  maxWidth: number,
  maxHeight: number
): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.src = base64Image;

    img.onload = () => {
      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > maxWidth) {
        newWidth = maxWidth;
        newHeight = (img.height * maxWidth) / img.width;
      }

      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = (img.width * maxHeight) / img.height;
      }

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0, newWidth, newHeight);

      canvas.toBlob((blob) => {
        const reader = new FileReader();

        if (blob) {
          reader.readAsDataURL(blob);

          reader.onloadend = () => {
            resolve(reader.result as string);
          };
        }

        reader.onerror = () => {
          reject("Error reading compressed image");
        };
      }, "image/jpeg");
    };

    img.onerror = () => {
      reject("Error loading image");
    };
  });
};

export const getImageAsBase64 = async (url: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise<string | null>((resolve) => {
      reader.onload = () => {
        if (typeof reader.result === "string") {
          const base64Data = reader.result.split(",")[1];
          resolve(base64Data);
        } else {
          resolve(null);
        }
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error fetching or converting image:", error);
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const root = document.documentElement;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    root.style.setProperty("--inner-height", `${windowDimensions.height}px`);
    root.style.setProperty("--inner-width", `${windowDimensions.width}px`);
  }, [windowDimensions]);

  return windowDimensions;
}
