export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export default async function getCroppedImg(imageSrc, pixelCrop, isPortrait) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // const imageSize = {
  //   width: isPortrait ? 768 : 1280,
  //   height: isPortrait ? 960 : 720,
  // };

  const imageSize = {
    width: isPortrait ? 1216 : 1536,
    height: isPortrait ? 1520 : 864,
  };

  if (!ctx) {
    return null;
  }

  // set canvas size to match the bounding box
  canvas.width = imageSize.width;
  canvas.height = imageSize.height;

  // Draw the cropped image onto the new canvas
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    imageSize.width,
    imageSize.height
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}
