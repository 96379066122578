import "./styles/app.scss";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./pages/router";
import { useEffect } from "react";
import useWindowDimensions, {
  getSessionTokenFromStorage,
  setSessionTokenInStorage,
} from "./lib/utils";
import { AlertProvider } from "./contexts/AlertContext";
import Navigation from "./components/Navigation";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressProvider } from "./contexts/ProgressContext";
import { TranslationProvider } from "./contexts/LangugageContext";
import { ImageOrientationProvider } from "./contexts/ImageOrientationContext";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PWC55PL3",
};
TagManager.initialize(tagManagerArgs);

function App() {
  useEffect(() => {
    const existingSessionToken = getSessionTokenFromStorage();

    if (existingSessionToken) {
      return;
    }

    const sessionToken = Date.now().toString();
    setSessionTokenInStorage(sessionToken);
  }, []);

  useWindowDimensions();

  useEffect(() => {
    // Set css variable of navigation height
    // const navHeight = document.querySelector(".Navigation")?.clientHeight;
    // const root = document.documentElement;
    // root.style.setProperty("--nav-height", `${navHeight}px`);
  }, []);

  return (
    <TranslationProvider>
      <ImageOrientationProvider>
        <AlertProvider>
          <ProgressProvider>
            <BrowserRouter>
              <ScrollToTop />
              <Navigation />
              <AppRouter />
            </BrowserRouter>
          </ProgressProvider>
        </AlertProvider>
      </ImageOrientationProvider>
    </TranslationProvider>
  );
}

export default App;
