import { Route, Routes } from "react-router-dom";

import routesData, { RouteTypes } from "./routesData"

const AppRouter = () => {
    const pageRoutes = routesData.map(({ path, title, element }: RouteTypes) => {
        return <Route key={title} path={path} element={element} />;
    });

    return <Routes>{pageRoutes}</Routes>;
};

export default AppRouter;