import React, { useEffect, useMemo, useState } from "react";
import Register from "./Register";
import Login from "./Login";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { BASE } from "../constants";
import classNames from "classnames";
import { useAlert } from "../contexts/AlertContext";

interface ImageData {
  sessionToken: string;
  filename: string;
  ctime: string;
  base64Image: string;
}

function Admin() {
  const [loggedIn, setLoggedIn] = useState(false); // New state for tracking user login
  const [submittedImages, setSubmittedImages] = useState<ImageData[]>([]);
  const [featuredImages, setFeaturedImages] = useState<ImageData[]>([]);
  const [lightboxVisible, setLightboxVisible] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState<number>(0);
  const [showDiscardDialog, setShowDiscardDialog] = useState(false);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [username, setUsername] = useState("");
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [fileNameToDelete, setFileNameToDelete] = useState("");

  const [hasChanges, setHasChanges] = useState(false);
  const [originalImages, setOriginalImages] = useState<{
    all: ImageData[];
    featured: ImageData[];
  }>({ all: [], featured: [] });

  const cachedImages = useMemo(
    () => submittedImages,
    [featuredImages, submittedImages]
  );
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  // Check if logged in
  useEffect(() => {
    const loggedInUser = Cookies.get("woodit_logged_in");

    if (loggedInUser) {
      setLoggedIn(true);
      setUsername(loggedInUser);
    }
  }, []);

  useEffect(() => {
    setUsername(Cookies.get("woodit_logged_in") || "");
  }, [loggedIn]);

  // Fetch and set images
  useEffect(() => {
    if (loggedIn) {
      Promise.all([
        fetchLatestImages(currentOffset),
        fetchFeaturedImages(),
      ]).then((values) => {
        setSubmittedImages(values[0]);
        setFeaturedImages(values[1]);
        setOriginalImages({ all: values[0], featured: values[1] });
      });
    }
  }, [loggedIn]);

  useEffect(() => {
    Promise.all([fetchLatestImages(currentOffset)]).then((values) => {
      setSubmittedImages(values[0]);
    });
  }, [currentOffset]);

  useEffect(() => {
    if (fileNameToDelete === "") {
      Promise.all([fetchLatestImages(currentOffset)]).then((values) => {
        setSubmittedImages(values[0]);
      });
    }
  }, [fileNameToDelete]);

  // Update featured images to display all images that are not in featured images
  useEffect(() => {
    console.log("featuredImages changed");
  }, [featuredImages]);

  // Prevent user from leaving page if there are changes
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    if (hasChanges) {
      window.addEventListener("beforeunload", unloadCallback);
    } else {
      window.removeEventListener("beforeunload", unloadCallback);
    }
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [hasChanges]);

  useEffect(() => {
    // Add keyboard events

    const keyListener = (event: KeyboardEvent) => {
      const { key } = event;

      if (key === "ArrowRight") {
        nextSlide();
      } else if (key === "ArrowLeft") {
        prevSlide();
      } else if (key === "Escape") {
        setLightboxVisible(false);
      }
    };

    window.addEventListener("keyup", keyListener);

    return () => {
      window.removeEventListener("keyup", keyListener);
    };
  }, [submittedImages, lightboxIndex]);

  const handleLogin = (loginResult: any) => {
    if (loginResult.status === "success") {
      setLoggedIn(true);
      Cookies.set("woodit_logged_in", loginResult.username, { expires: 1 });
    }
  };

  const handleLogout = () => {
    Cookies.remove("woodit_logged_in");
    navigate(0); // refresh page
  };

  const fetchLatestImages = async (offset: number = 0) => {
    const res = await fetch(`${BASE}/api/images/latest?offset=${offset}`);
    try {
      const data = await res.json();
      return data.latestImages;
    } catch (error) {
      console.error("Error fetching latest images:", error);
    }
  };

  const fetchFeaturedImages = async () => {
    const res = await fetch(`${BASE}/api/images/featured`);

    try {
      const data = await res.json();
      return data.featuredImages;
    } catch (error) {
      console.error("Error fetching latest images:", error);
    }
  };

  const addToFeatured = (image: ImageData) => {
    setHasChanges(true);
    setFeaturedImages([...featuredImages, image]);
  };

  const removeFromFeatured = (index: number) => {
    const cloneArr = [...featuredImages];
    cloneArr.splice(index, 1);
    setFeaturedImages(cloneArr);
    setHasChanges(true);
  };

  const deleteFileFromServer = async (filename: string) => {
    const res = await fetch(`${BASE}/api/images/delete-single${filename}`, {
      method: "DELETE",
    });
    try {
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const discardChanges = () => {
    setSubmittedImages(originalImages.all);
    setFeaturedImages(originalImages.featured);
    setShowDiscardDialog(false);
    setHasChanges(false);
  };

  const publishFeaturedImages = async () => {
    const res = await fetch(`${BASE}/api/images/featured`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ images: featuredImages }),
    });

    if (!res.ok) {
      console.error("Error publishing changes");
      return null;
    }

    try {
      const data = await res.json();
      console.log("Changes published", data);
      setHasChanges(false);
      setShowPublishDialog(false);
      showAlert("Changes published", "success");
    } catch (error) {
      console.error("Error publishing changes:", error);
      showAlert("Error publishing changes", "error");
      return null;
    }
  };

  const openLightbox = (index: number) => {
    setLightboxIndex(index);
    setLightboxVisible(true);
  };

  const nextSlide = () => {
    if (lightboxIndex >= submittedImages.length - 1) {
      setLightboxIndex(0);
    } else {
      setLightboxIndex((prev) => prev + 1);
    }
  };

  const prevSlide = () => {
    if (lightboxIndex <= 0) {
      setLightboxIndex(submittedImages.length - 1);
    } else {
      setLightboxIndex((prev) => prev - 1);
    }
  };

  console.log(submittedImages);

  return (
    <div className="Admin">
      {loggedIn ? (
        <div>
          <div
            className={classNames("Admin-nav", {
              "is-hidden": lightboxVisible,
            })}
          >
            <div className="Admin-navInfo">
              <p>
                Logged in as <span className="u-bold">{username}</span>
              </p>
              <button
                className="Button Button--logout Button--small"
                onClick={() => setShowLogoutDialog(true)}
              >
                Log out
              </button>
            </div>

            <div className="Admin-navActions">
              <button
                className="Button Button--red"
                disabled={!hasChanges}
                onClick={() => setShowDiscardDialog(true)}
              >
                Discard all changes
              </button>
              <button
                className="Button Button--blue"
                disabled={!hasChanges}
                onClick={() => setShowPublishDialog(true)}
              >
                Publish changes
              </button>
            </div>
          </div>

          <div>
            <h2>Showcased images</h2>
            <div className="Admin-images Admin-images--featured">
              {featuredImages.length === 0 && <p>No featured images.</p>}

              {featuredImages.map((image, index) => (
                <div className="Admin-imageWrap" key={index}>
                  <img
                    onClick={() => openLightbox(index)}
                    className="Admin-image"
                    src={`data:image/jpeg;base64,${image.base64Image}`}
                    alt={`Image ${image.filename}`}
                  />
                  <div className="Admin-imageControls">
                    <button
                      className="Button Button--small Button--neutral"
                      onClick={() => removeFromFeatured(index)}
                    >
                      - Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h2>Latest images</h2>
            <div className="Admin-images">
              {submittedImages
                .filter((image) => {
                  let found = false;
                  featuredImages.forEach((featureImage) => {
                    if (image.filename === featureImage.filename) {
                      found = true;
                      return;
                    }
                  });
                  return !found;
                })
                .map((image, index) => (
                  <div className="Admin-imageWrap" key={index}>
                    <img
                      onClick={() => openLightbox(index)}
                      className="Admin-image"
                      src={`data:image/jpeg;base64,${image.base64Image}`}
                      alt={`Image ${image.filename}`}
                    />
                    <div className="Admin-imageControls">
                      <button
                        className="Button Button--small Button--neutral"
                        onClick={() => addToFeatured(image)}
                      >
                        + Add
                      </button>
                      <button
                        className="Button Button--small Button--red"
                        onClick={() => {
                          setFileNameToDelete(image.filename);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
            </div>
            <div className="Admin-offsetButtonsWrapper">
              <button
                className="Button Button--small"
                onClick={() => {
                  setCurrentOffset(clamp(currentOffset - 24, 0, 999999));
                }}
              >
                Previous
              </button>
              <button
                className="Button Button--small "
                onClick={() => {
                  setCurrentOffset(clamp(currentOffset + 24, 0, 999999));
                }}
              >
                Next
              </button>
            </div>
          </div>

          <div
            className={classNames("Lightbox", {
              "is-visible": lightboxVisible,
            })}
          >
            <div
              className="Lightbox-closeArea"
              onClick={() => setLightboxVisible(false)}
            ></div>
            <button
              className="Lightbox-button Lightbox-button--close"
              onClick={() => setLightboxVisible(false)}
            >
              &times;
            </button>
            <div className="Lightbox-controls">
              <button
                className="Lightbox-button Lightbox-button--prev"
                onClick={prevSlide}
              >
                &larr;
              </button>
              <button
                className="Lightbox-button Lightbox-button--next"
                onClick={nextSlide}
              >
                &rarr;
              </button>
            </div>
            <div className="Lightbox-imagesWrap">
              {cachedImages.map(({ base64Image, filename }, index) => (
                <img
                  key={index}
                  className={classNames("Lightbox-image", {
                    "is-active": index === lightboxIndex,
                  })}
                  src={`data:image/jpeg;base64,${base64Image}`}
                  alt={`Image ${filename}`}
                />
              ))}
            </div>
          </div>

          <div
            className={classNames("Dialog", {
              "is-visible": showDiscardDialog,
            })}
            role="dialog"
          >
            <p>You're about to discard all changes</p>
            <button onClick={discardChanges} className="Button">
              Discard all changes
            </button>
            <button
              className="u-underline"
              onClick={() => setShowDiscardDialog(false)}
            >
              Continue editing
            </button>
          </div>

          <div
            className={classNames("Dialog", {
              "is-visible": showPublishDialog,
            })}
            role="dialog"
          >
            <p>Please confirm to publish your changes.</p>
            <button
              onClick={publishFeaturedImages}
              className="Button Button--blue"
            >
              Publish changes
            </button>
            <button
              className="u-underline"
              onClick={() => setShowPublishDialog(false)}
            >
              Continue editing
            </button>
          </div>

          <div
            className={classNames("Dialog", {
              "is-visible": showLogoutDialog,
            })}
            role="dialog"
          >
            <p>Are you sure you want to log out?</p>
            <button onClick={handleLogout} className="Button Button--yellow">
              Continue
            </button>
            <button
              className="u-underline"
              onClick={() => setShowLogoutDialog(false)}
            >
              Go back
            </button>
          </div>

          <div
            className={classNames("Dialog", {
              "is-visible": fileNameToDelete !== "",
            })}
            role="dialog"
          >
            <p>Are you sure you want to delete the image?</p>
            <p>
              OBS! This will also remove the image
              <br />
              for the user who made it.
            </p>
            <p>You can not undo this operation.</p>
            <button
              onClick={() => {
                deleteFileFromServer(fileNameToDelete);
                setFileNameToDelete("");
              }}
              className="Button Button--red"
            >
              Delete
            </button>
            <button
              className="u-underline"
              onClick={() => {
                setFileNameToDelete("");
              }}
            >
              Go back
            </button>
          </div>

          <div
            className={classNames("Dialog-overlay", {
              "is-visible":
                showPublishDialog ||
                showDiscardDialog ||
                showLogoutDialog ||
                fileNameToDelete !== "",
            })}
          ></div>
        </div>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default Admin;

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}
