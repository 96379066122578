import FacebookLogo from "../img/facebook-logo-middle.svg";
import XLogo from "../img/x-logo.svg";
import CopyIcon from "../img/copy-icon.svg";
import { useEffect, useState } from "react";

const ShareModal = ({ onCloseArea, url }: any) => {
  const [showCopiedBox, setShowCopiedBox] = useState<boolean>(false);

  useEffect(() => {
    if (!showCopiedBox) {
      return;
    }

    const timeout = setTimeout(() => {
      setShowCopiedBox(false);
    }, 2500);
    return () => clearInterval(timeout);
  }, [showCopiedBox]);

  return (
    <>
      <section className="ShareModal">
        <a
          href={`https://www.facebook.com/sharer.php?p[url]=${url}`}
          target="_blank"
          title="Facebook"
          className="ShareModal-button"
        >
          <img src={FacebookLogo} alt="" className="--loaded" />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${url}`}
          target="_blank"
          title="X"
          className="ShareModal-button"
        >
          <img src={XLogo} alt="" className="--loaded" />
        </a>
        <button
          className="ShareModal-button"
          title="Copy url"
          onClick={() => {
            navigator.clipboard.writeText(url);
            setShowCopiedBox(true);
          }}
        >
          <img src={CopyIcon} alt="" className="--loaded" />
        </button>
      </section>
      <button className="ShareModal-closeArea" onClick={onCloseArea}></button>

      <div
        className="ShareModal-copiedBox"
        style={{ opacity: showCopiedBox ? "1" : "0" }}
      >
        <p>Copied url to clipboard</p>
      </div>
    </>
  );
};

export default ShareModal;
