import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import { BASE } from "../constants";
import classNames from "classnames";
import { useAsyncError } from "react-router-dom";

function Register() {
    const [formStatus, setFormStatus] = useState<"ready" | "success" | "error">("ready");
    const [formMessage, setFormMessage] = useState<string>("");
    const messageRef = useRef<HTMLDivElement>(null);
    const formRef = useRef<HTMLFormElement>(null)

    const handleRegister = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        const target = event.target as HTMLFormElement;
        const formData = new FormData(target);
        const name = formData.get("name");
        const username = formData.get("username");
        const password = formData.get("password");
        const secret = formData.get("secret");

        const res = await fetch(`${BASE}/api/admin/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name, username, password, secret }),
        });

        try {
            const data = await res.json();
            console.log(data);
            setFormMessage(data.message);

            if (res.ok) {
                setFormStatus("success");
                clearFormFields();
            } else {
                setFormStatus("error");
            }
        } catch (error: any) {
            console.error(error);
            setFormStatus("error");
            setFormMessage(error.message);
        }
    };

    useEffect(() => {
        if (formStatus === "ready") {
            setFormMessage("");
        }
    }, [formStatus]);

    const handleFieldChange = () => {
        if (formStatus !== "ready") {
            setFormStatus("ready");
            setFormMessage("");
        }
    }

    const clearFormFields = () => {
        const form = formRef.current;

        if (form) {
            form.querySelectorAll("input").forEach(field => field.value = "");
        }
    }

    return (
        <div>
            <form
                className={classNames("Form", {
                    "is-error": formStatus === "error",
                    "is-success": formStatus === "success",
                })}
                ref={formRef}
                onSubmit={handleRegister}
            >
                <div className="Form-fieldWrap">
                    <label className="Form-label" htmlFor="name">Your name</label>
                    <input
                        className="Form-field"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Firstname Lastname"
                        required
                        onChange={handleFieldChange}
                    />
                </div>
                <div className="Form-fieldWrap">
                    <label className="Form-label" htmlFor="username">Choose a username</label>
                    <input
                        className="Form-field"
                        name="username"
                        id="username"
                        type="text"
                        placeholder="Username"
                        required
                        onChange={handleFieldChange}
                    />
                </div>
                <div className="Form-fieldWrap">
                    <label className="Form-label" htmlFor="password">Choose a password</label>

                    <input
                        className="Form-field"
                        name="password"
                        id="password"
                        type="password"
                        placeholder="Password"
                        required
                        onChange={handleFieldChange}
                    />
                </div>
                <div className="Form-fieldWrap">
                    <label className="Form-label" htmlFor="secret">Add the secret key provided by your development team</label>
                    <input
                        className="Form-field"
                        name="secret"
                        id="secret"
                        type="password"
                        placeholder="Secret"
                        required
                        onChange={handleFieldChange}
                    />
                </div>
                <button className="Button">Register</button>
                {formStatus !== "ready" && (
                    <div className="Form-message" ref={messageRef}>{formMessage}</div>
                )}
            </form>
        </div>

    );
}

export default Register;
