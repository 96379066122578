import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASE } from "../constants";
import BuildImg from "../img/background-images/04.jpg";
import Footer from "../components/Footer";
import classNames from "classnames";
import ShareIcon from "../img/share-icon.svg";
import DownloadIcon from "../img/download-icon.svg";
import UploadNewIcon from "../img/cross-icon.png";
import ShareModal from "../components/ShareModal";

interface ResponseImage {
  filename: string;
  base64Image: string;
}

const SingleImage = () => {
  const navigate = useNavigate();
  const [singleImage, setSingleImage] = useState<ResponseImage | null>(null);
  const [searchParams] = useSearchParams();
  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const [imageIsLoaded, setImageIsLoaded] = useState<boolean>(false);
  const [shareModalIsOpen, setShareModalIsOpen] = useState<boolean>(false);

  const shareHandling = () => {
    const winNavigator: any = window.navigator;
    const shareUrl = `/voila?image=${singleImage?.filename}`;
    if (winNavigator?.share) {
      winNavigator
        .share({
          title:
            "Wood it! Turn any building into wood with the click of a button | Stora Enso",
          text: "",
          url: shareUrl,
          description:
            "Wood it! Turn any building into wood with the click of a button | Stora Enso",
        })
        .then(() => console.log("Successful share"))
        .catch((error: any) => console.log("Error sharing:", error));
    } else {
      //If share not supported (Computer, etc.), make custom share modal
      setShareModalIsOpen(true);
    }
  };

  useEffect(() => {
    // Function to fetch the latest images
    const fetchLatestImages = async () => {
      const image = searchParams.get("image");

      //If no image id -> set to first in explore
      if (!image || image.length === 0) {
        const res = await fetch(`${BASE}/api/images/latest`);

        try {
          const data = await res.json();
          const latestExploreImage: any = data.latestImages[0];
          const displayImage: ResponseImage = {
            filename: latestExploreImage.filename,
            base64Image: latestExploreImage.base64Image,
          };
          setSingleImage(displayImage);
        } catch (error: any) {
          console.error("Error fetching image filenames:", error);
        }

        //setSingleImage();
        return;
      }
      //Else try to get image with id

      try {
        const response = await fetch(`${BASE}/api/images/get-single`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ image: image }),
        });
        const data = await response.json();
        setSingleImage(data);
      } catch (error) {
        console.error("Error fetching latest images:", error);
        navigate("/");
      }
    };
    if (singleImage === null) {
      fetchLatestImages();
    }
    if (singleImage !== null) {
      const image = new Image();
      image.src = `data:image/jpeg;base64,${singleImage.base64Image}`;
      image.onload = async () => {
        try {
          const ratio = image.width / image.height;
          if (ratio < 1) {
            setIsPortrait(true);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
    }
  }, [singleImage]);

  useEffect(() => {
    setTimeout(() => {
      const animationWrappers = Array.from(
        document.querySelectorAll(".js-observable-graphic")
      );
      animationWrappers.forEach((animationWrapper) => {
        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              animationWrapper.classList.add("--animate");
            }
          },
          { threshold: 0.25 }
        );
        observer.observe(animationWrapper);
      });
    }, 1000);
  }, [imageIsLoaded]);

  return (
    <section
      className={classNames("SingleImage", {
        "--is-portrait": isPortrait,
        "--is-hidden": !imageIsLoaded,
      })}
    >
      {singleImage && (
        <section className="SingleImage-imageAndArrowWrapper">
          {shareModalIsOpen && (
            <ShareModal
              onCloseArea={() => {
                setShareModalIsOpen(false);
              }}
              url={`${location.hostname}/voila?image=${singleImage.filename}`}
            />
          )}
          <figure className="SingleImage-imageWrapper">
            <img
              src={`data:image/jpeg;base64,${singleImage.base64Image}`}
              alt={`Image ${singleImage.filename}`}
              onLoad={() => {
                setImageIsLoaded(true);
              }}
            />
            <div className="SingleImage-shareButtonsWrapper">
              <button className="u-button" onClick={shareHandling}>
                Share
              </button>
              <a
                href={`data:image/jpeg;base64,${singleImage.base64Image}`}
                download={singleImage.filename}
                title="Download image"
                className="u-button"
              >
                Save
              </a>
              <button
                className="u-button"
                onClick={() => {
                  navigate("/upload");
                }}
              >
                Upload new
              </button>
              <button className="u-iconButton" onClick={shareHandling}>
                <img src={ShareIcon} alt="" />
              </button>
              <a
                href={`data:image/jpeg;base64,${singleImage.base64Image}`}
                download={singleImage.filename}
                title="Download image"
                className="u-iconButton"
              >
                <img src={DownloadIcon} alt="" className="--loaded" />
              </a>
              <button
                className="u-iconButton"
                style={{ padding: 0 }}
                onClick={() => {
                  navigate("/upload");
                }}
              >
                <img src={UploadNewIcon} alt="" />
              </button>
            </div>
          </figure>
          <div className="SingleImage-topWrapper">
            <div className="SingleImage-topWrapperContent">
              <h2>Voilà</h2>
              <a
                className="Button-bigArrowWrapper Button-bigArrowWrapper--yellowText"
                href={"/profile?gallery=explore"}
              >
                <p>Explore more wooden masterpieces</p>
                <span className="Button-bigArrow"></span>
              </a>
            </div>
          </div>
        </section>
      )}

      <section
        className={classNames("SingleImage-graphicsContentWrapper", {
          "--is-landscape": !isPortrait,
        })}
      >
        <div className="SingleImage-infoBlocksWrapper">
          <a
            href="https://www.storaenso.com/en/products/mass-timber-construction/sylva/sylva-talks"
            target="_blank"
            className="SingleImage-infoTextBlock"
            style={{ order: 0 }}
          >
            <h3>
              You’ve just made a building
              <br />
              better for people and the planet.
            </h3>
            <p>
              Read more about how to build easier in wood{" "}
              <span className="SingleImage-inlineArrow"></span>
            </p>
          </a>

          <div className="SingleImage-graphicWrapperBlock" style={{ order: 1 }}>
            <div className="AnimatedGraphic-animationWrapper js-observable-graphic">
              <div className="AnimatedGraphic-animationPart AnimatedGraphic-housePart--gable"></div>
              <div className="AnimatedGraphic-animationPart AnimatedGraphic-housePart--roof"></div>
              <div className="AnimatedGraphic-animationPart AnimatedGraphic-housePart--side"></div>
            </div>
          </div>

          <div className="SingleImage-graphicWrapperBlock SingleImage-treeWrapperBlock">
            <div className="AnimatedGraphic-animationWrapper js-observable-graphic">
              <div className="AnimatedGraphic-singleTree">
                <div className="AnimatedGraphic-animationPart AnimatedGraphic-treePart--trunk"></div>
                <div className="AnimatedGraphic-animationPart AnimatedGraphic-treePart--crown"></div>
              </div>
              <div className="AnimatedGraphic-singleTree --second">
                <div className="AnimatedGraphic-animationPart AnimatedGraphic-treePart--trunk"></div>
                <div className="AnimatedGraphic-animationPart AnimatedGraphic-treePart--crown"></div>
              </div>
            </div>
          </div>

          <div className="SingleImage-infoTextBlock" style={{ order: 3 }}>
            <h3>
              Wood stores carbon = less CO<sub>2</sub> in the air! And it grows
              back!
            </h3>
          </div>

          <figure className="SingleImage-imageBlock" style={{ order: 5 }}>
            <img src={BuildImg} alt="" />
          </figure>

          <a
            href="https://www.storaenso.com/en/products/mass-timber-construction/sylva"
            target="_blank"
            className="SingleImage-bottomTextBlock"
            style={{ order: 6 }}
          >
            <h2>Easy-to-build</h2>
            <p>
              Sylva, a new way of constructing in wood{" "}
              <span
                className="SingleImage-inlineArrow --yellow"
                style={{ transform: "scale(2) translate(0.1rem, 0.05rem)" }}
              ></span>
            </p>
          </a>
        </div>
      </section>
      <Footer />
    </section>
  );
};

export default SingleImage;
