import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShareIcon from "../img/share-icon.svg";
import DownloadIcon from "../img/download-icon.svg";
import ShareModal from "./ShareModal";

interface HouseProps {
  fileName: string;
  base64Image: string;
  cssGridOrder: number;
}

const ClickableHouse = ({
  fileName,
  base64Image,
  cssGridOrder,
}: HouseProps) => {
  const navigate = useNavigate();
  const [imageWasLoaded, setImageWasLoaded] = useState<boolean>(false);
  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const [shareModalIsOpen, setShareModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const image = new Image();
    image.src = `data:image/jpeg;base64,${base64Image}`;
    image.onload = async () => {
      try {
        const ratio = image.width / image.height;
        if (ratio < 1) {
          setIsPortrait(true);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
  }, []);

  const shareHandling = () => {
    const winNavigator: any = window.navigator;
    const shareUrl = `/voila?image=${fileName}`;
    if (winNavigator?.share) {
      winNavigator
        .share({
          title:
            "Wood it! Turn any building into wood with the click of a button | Stora Enso",
          text: "",
          url: shareUrl,
          description:
            "Wood it! Turn any building into wood with the click of a button | Stora Enso",
        })
        .then(() => console.log("Successful share"))
        .catch((error: any) => console.log("Error sharing:", error));
    } else {
      //If share not supported (Computer, etc.), make custom share modal
      setShareModalIsOpen(true);
    }
  };

  return (
    <section
      style={{ order: cssGridOrder, gridColumn: isPortrait ? "" : "span 2" }}
      className="Feed-clickableHouse"
    >
      {shareModalIsOpen && (
        <ShareModal
          onCloseArea={() => {
            setShareModalIsOpen(false);
          }}
          url={`${location.hostname}/voila?image=${fileName}`}
        />
      )}
      <button
        className="Feed-clickableHouseNavButton"
        onClick={() => {
          navigate(`/voila?image=${fileName}`);
        }}
      ></button>
      <figure key={fileName}>
        <img
          className={imageWasLoaded ? "--loaded" : ""}
          onLoad={() => {
            setImageWasLoaded(true);
          }}
          src={`data:image/jpeg;base64,${base64Image}`}
          alt={`Image ${fileName}`}
        />
        <div className="u-iconButtonWrapper">
          <button className="u-iconButton" onClick={shareHandling}>
            <img src={ShareIcon} alt="" className="--loaded" />
          </button>
          <a
            href={`data:image/jpeg;base64,${base64Image}`}
            download={fileName}
            title="Download image"
            className="u-iconButton"
          >
            <img src={DownloadIcon} alt="" className="--loaded" />
          </a>
        </div>
      </figure>
    </section>
  );
};

export default ClickableHouse;
