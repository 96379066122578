import { useEffect, useState } from "react";
import {
  fetchGenerationId,
  fetchGenerationImages,
  getImageAsBase64,
  getSessionTokenFromStorage,
} from "../lib/utils";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { BASE } from "../constants";
import { useAlert } from "../contexts/AlertContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import LoaderScreen from "../components/LoaderScreen";
import { useProgress } from "../contexts/ProgressContext";
import { useImageOrientation } from "../contexts/ImageOrientationContext";

function Generation() {
  const [generatedImageSource, setGeneratedImageSource] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [generationProgress, setGenerationProgress] = useState<
    "test" | "idle" | "loading" | "pending" | "complete" | "saved"
  >("idle");
  const { isPortrait } = useImageOrientation();
  const [initImageSource, setInitImageSource] = useState<string | null>(null);
  const [isNSFW, setIsNSFW] = useState<boolean>(false);
  const [displayNSFWMessage, setDisplayNSFWMessage] = useState<boolean>(false);

  // const [initImageSize, setInitImageSize] = useState<{
  //   width: string;
  //   height: string;
  // } | null>(
  //   isPortrait
  //     ? { width: "768", height: "960" }
  //     : { width: "1280", height: "720" }
  // );

  //Max resolutions under or equal to max 1536px.
  //Portrait 4/5
  //1216 : 1520

  //Landscape 16/9
  //1536 : 864

  const [initImageSize, setInitImageSize] = useState<{
    width: string;
    height: string;
  } | null>(
    isPortrait
      ? { width: "1216", height: "1520" }
      : { width: "1536", height: "864" }
  );

  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { setProgress } = useProgress();

  /* EFFECTS START ********/
  useEffect(() => {
    // Set submitted init image
    const initImageId = searchParams.get("init");
    if (initImageId) {
      fetchInitImage(initImageId)
        .then((base64Image) => {
          setInitImageSource(base64Image);
          initiateGeneration();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  // Get already submitted generation
  useEffect(() => {
    const generationId = searchParams.get("gen");

    if (generationId) {
      setGenerationProgress("loading");
      fetchGenerationImage(generationId);
    }
  }, []);

  useEffect(() => {
    if (generationProgress == "complete") {
      if (!isNSFW) {
        saveGeneratedImage();
      } else {
        setDisplayNSFWMessage(true);
      }
      deleteAllInitFiles();
    }
  }, [generationProgress]);

  /* EFFECTS END **********/
  /***********************/

  /****************************/
  /* FUNCTIONS START *********/

  // Delete all init files on server
  const deleteAllInitFiles = async () => {
    const sessionToken = getSessionTokenFromStorage();
    const res = await fetch(
      `${BASE}/api/images/delete-all-init-files${sessionToken}`,
      {
        method: "DELETE",
      }
    );

    try {
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  // Fetch init image from backend server
  const fetchInitImage = async (id: string) => {
    const res = await fetch(`${BASE}/api/images/init-image/${id}`);

    try {
      const base64Image = await res.json();
      return base64Image;
    } catch (error) {
      console.error("Error fetching image filenames:", error);
    }
  };

  // Fetch already generated image from Leonardo
  const fetchGenerationImage = (generationId: string) => {
    if (!generationId) {
      console.error("No generation ID found");
    } else {
      // Fetch generation on an interval until status is "Complete"
      const interval = setInterval(() => {
        fetchGenerationImages(generationId).then((data) => {
          if (data && data.status === "COMPLETE") {
            const imageSource = data.generated_images.map(
              (img: any) => img.url
            );
            const nsfw = data.generated_images[0].nsfw;

            setIsNSFW(nsfw);

            if (!nsfw) {
              setGeneratedImageSource(imageSource[0]);
            }

            //Mark out the next line to work on the generation animation page
            setGenerationProgress("complete");
            setProgress("idle");
            clearInterval(interval);
          }
        });
      }, 2000);
    }
  };

  // Initiate first generation of new image based on submitted image
  const initiateGeneration = () => {
    //Google tag manager
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({ event: "wood-it-start" });
    //End Google tag manager

    const initImageId = searchParams.get("init");
    setProgress("generating");
    setGenerationProgress("pending");

    if (!initImageId) {
      console.error("No init image id");
      return;
    }

    if (!initImageSize) {
      console.error("No image size");
      return;
    }

    fetchGenerationId(
      initImageId,
      initImageSize.width,
      initImageSize.height
    ).then((data) => {
      const { generationId } = data;
      setSearchParams((params) => {
        params.set("gen", generationId);
        return params;
      });
      fetchGenerationImage(generationId);
    });
  };

  // Start new generation based on submitted image
  const startnewGeneration = () => {
    setGenerationProgress("pending");
    setProgress("generating");
    searchParams.delete("gen");

    const initImageId = searchParams.get("init");

    if (!initImageId) {
      console.error("No init image id");
      return;
    }

    if (!initImageSize) {
      console.error("No image size");
      return;
    }

    fetchGenerationId(
      initImageId,
      initImageSize.width,
      initImageSize.height
    ).then((data) => {
      const { generationId } = data;
      setSearchParams((params) => {
        params.set("gen", generationId);
        return params;
      });
      fetchGenerationImage(generationId);
    });
  };

  // Save generated image to user session
  const saveGeneratedImage = async () => {
    const generationId = searchParams.get("gen");
    const base64Image = await getImageAsBase64(generatedImageSource);
    const sessionToken = getSessionTokenFromStorage();

    const res = await fetch(`${BASE}/api/images/save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        genImageData: base64Image,
        genImageId: generationId,
        sessionToken: sessionToken,
      }),
    });

    if (!res.ok) {
      if (res.status == 409) {
        console.error("Image already exist");
        showAlert("You have already saved this image", "error");
        navigate("/profile");
        return;
      }

      console.error("Failed to fetch:", res);
      showAlert(res.statusText, "error");
      return;
    }

    try {
      const data = await res.json();
      navigate("/profile");

      navigate("/single-image?image=gen__" + generationId + ".jpeg");
    } catch (error: any) {
      showAlert(error.message, "error");
      console.error(error);
    }
  };

  /* FUNCTIONS END *******/
  /**********************/

  return (
    <main>
      <section className="Generation">
        {/* Dev purposes */}
        {generationProgress === "idle" && (
          <>
            <p
              style={{
                textAlign: "center",
                paddingTop: "8rem",
                color: "white",
              }}
            >
              This is a temporary step for development purposes, <br /> in
              production generation will start immediately.
            </p>
            <div className="Navigation-progressWrap">
              <button
                className="Navigation-progressButton"
                onClick={initiateGeneration}
              >
                {/* <div className="Navigation-progressIconWrap">
                  <FontAwesomeIcon icon={faRobot} size="2x" />
                </div>
                <span>Start generation</span> */}
              </button>
            </div>
          </>
        )}

        {generationProgress === "loading" && (
          <div>
            <div className="Navigation-progressWrap">
              <div
                className="Navigation-progressButton"
                onClick={() => navigate("/upload")}
              >
                <i className="Navigation-progressIconWrap">
                  <FontAwesomeIcon icon={faImage} size="2x" />
                </i>
                <span className="">Loading image...</span>
              </div>
            </div>
          </div>
        )}

        {generationProgress === "pending" && <LoaderScreen />}

        {displayNSFWMessage && (
          <div className="Generation-nsfwWrapper">
            <h2>
              The AI could not process this image, please try another one.
            </h2>
            <Link className={"u-button"} id="profile" to="/upload">
              <p>Upload new</p>
            </Link>
          </div>
        )}
      </section>
    </main>
  );
}

export default Generation;
