import { useState } from "react";
import ImageCropper from "../components/ImageCropper";

const originalImage =
  "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";

const Crop = () => {
  const [croppedImage, setCroppedImage] = useState<string | null>(null);

  return (
    <div>
      {originalImage && (
        <ImageCropper
          originalImageUrl={originalImage}
          onCroppedImage={(image) => {
            setCroppedImage(image);
          }}
        />
      )}
      <div>
        {croppedImage && (
          <img
            style={{ position: "absolute", zIndex: "100" }}
            src={croppedImage}
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default Crop;
