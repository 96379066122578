import { useEffect, useState } from "react";
import BackgroundImage01 from "../img/background-images/01.jpg";
import BackgroundImage02 from "../img/background-images/02.jpg";
import BackgroundImage03 from "../img/background-images/03.jpg";
import BackgroundImage04 from "../img/background-images/04.jpg";
import BackgroundImage05 from "../img/background-images/05.jpg";
import BackgroundImage06 from "../img/background-images/06.jpg";

const fadeTimeSeconds = 2; //For 60 sec framerate.

//OBS, only work with even amount images for now.
const images = [
  BackgroundImage01,
  BackgroundImage03,
  BackgroundImage04,
  BackgroundImage06,
];

enum FadeDirection {
  Down,
  Idle,
  Up,
}

const Slideshow = () => {
  const [interpolateValue, setInterpolateValue] = useState<number>(0);
  const [fadeDirection, setFadeDirection] = useState<FadeDirection>(
    FadeDirection.Idle
  );
  const [img01Index, setImg01Index] = useState<number>(0);
  const [img02Index, setImg02Index] = useState<number>(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (interpolateValue === 0) {
        setFadeDirection(FadeDirection.Up);
      } else {
        setFadeDirection(FadeDirection.Down);
      }
    }, 8000);
    return () => clearInterval(interval);
  }, [fadeDirection]);

  useEffect(() => {
    if (fadeDirection === FadeDirection.Up) {
      const interval = setInterval(() => {
        setInterpolateValue(interpolateValue + 1 / fadeTimeSeconds / 60);
      }, 10);
      if (interpolateValue >= 1) {
        setFadeDirection(FadeDirection.Idle);
        setInterpolateValue(1);
        if (img01Index < images.length - 2) {
          setImg01Index(img01Index + 2);
        } else {
          setImg01Index(0);
        }
        return () => clearInterval(interval);
      }
      return () => clearInterval(interval);
    } else if (fadeDirection === FadeDirection.Down) {
      const interval = setInterval(() => {
        setInterpolateValue(interpolateValue - 1 / fadeTimeSeconds / 60);
      }, 10);
      if (interpolateValue <= 0) {
        setFadeDirection(FadeDirection.Idle);
        setInterpolateValue(0);
        if (img01Index < images.length - 1) {
          setImg02Index(img01Index + 1);
        }
        return () => clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [fadeDirection, interpolateValue]);

  return (
    <>
      <img
        className="Section-backgroundImage"
        src={images[img01Index]}
        alt=""
        style={{ opacity: lerp(0.85, 0, interpolateValue) }}
      />
      <img
        className="Section-backgroundImage"
        src={images[img02Index]}
        alt=""
        style={{ opacity: lerp(0, 0.85, interpolateValue) }}
      />
    </>
  );
};

function lerp(v0: number, v1: number, t: number) {
  return (1 - t) * v0 + t * v1;
}

export default Slideshow;
