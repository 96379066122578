import { useEffect, useMemo, useState } from "react";
import { getSessionTokenFromStorage } from "../lib/utils";
import { BASE } from "../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import ClickableHouse from "../components/ClickableHouse";
import classNames from "classnames";
import AddCircle from "../img/add-circle.svg";

function Profile() {
  const pageContent = {
    title: "Mina bilder",
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const sessionToken = getSessionTokenFromStorage();
  const [sessionImages, setSessionImages] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [scrollDistanceFromBottom, setScrollDistanceFromBottom] =
    useState<number>(99999);

  const [isShowingAllImages, setIsShowingAllImages] = useState<boolean>(
    //Cookies.get("woodit_show_all_images") === "1"
    searchParams.get("gallery") === "explore" ? true : false
  );
  const navigate = useNavigate();

  //Profile images
  const fetchSessionImages = async () => {
    const res = await fetch(`${BASE}/api/images/session/${sessionToken}`);
    try {
      const data = await res.json();
      return data.imageResponses;
    } catch (error: any) {
      console.error("Error fetching image filenames:", error);
    }
  };

  //All images
  const fetchFeedImages = async () => {
    const res = await fetch(`${BASE}/api/images/featured`);

    try {
      const data = await res.json();
      return data.featuredImages;
    } catch (error: any) {
      console.error("Error fetching image filenames:", error);
    }
  };

  //Get distance from bottom
  useEffect(() => {
    const onScroll = (e: any) => {
      const scrollPosition = window.pageYOffset;
      const windowSize = window.innerHeight;
      const bodyHeight = document.body.offsetHeight;

      const scrollDistanceFromBottom = Math.max(
        bodyHeight - (scrollPosition + windowSize),
        0
      );
      setScrollDistanceFromBottom(scrollDistanceFromBottom);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    // Fetch the latest images only if the cache is empty
    if (cachedSessionImages.length === 0) {
      fetchSessionImages().then((sessionImages) => {
        if (sessionImages && sessionImages.length) {
          setSessionImages(sessionImages.reverse());
        }
      });
    }

    if (cachedFeedImages.length === 0) {
      fetchFeedImages().then((feedImages) => {
        if (feedImages && feedImages.length) {
          setAllImages(feedImages.reverse());
        }
      });
    }
  }, []);

  useEffect(() => {
    document.body.classList.toggle("--is-explore-gallery", isShowingAllImages);
  }, [isShowingAllImages]);

  // Cache the latestImages using useMemo
  const cachedSessionImages = useMemo(() => sessionImages, [sessionImages]);
  const cachedFeedImages = useMemo(() => allImages, [allImages]);

  return (
    <main>
      {/* Navigation progress */}
      <div className="Navigation-progressWrap">
        <button
          className="Navigation-progressButton"
          onClick={() => navigate("/upload")}
        >
          <a className="Button-bigArrowWrapper Button-bigArrowWrapper">
            <p
              className={
                scrollDistanceFromBottom <= (window.innerWidth < 1024 ? 65 : 80)
                  ? "--yellowText"
                  : ""
              }
            >
              {isShowingAllImages
                ? "Turn any building into wood"
                : "Upload New"}{" "}
            </p>
            <span className="Button-bigArrow"></span>
          </a>
        </button>
      </div>
      <section className="Section">
        <div className="Feed">
          <div className="Feed-feedToggleWrapper">
            <button
              className={classNames(
                "u-button",
                isShowingAllImages ? "" : "--blured"
              )}
              onClick={() => {
                setIsShowingAllImages(false);
                Cookies.set("woodit_show_all_images", "0");
              }}
            >
              My gallery
            </button>
            <button
              className={classNames(
                "u-button",
                isShowingAllImages ? "--blured" : ""
              )}
              onClick={() => {
                setIsShowingAllImages(true);
                Cookies.set("woodit_show_all_images", "1");
              }}
            >
              Explore all
            </button>
          </div>
          <div className="Feed-grid">
            {!isShowingAllImages &&
              sessionImages.map(({ filename, base64Image }, i) => (
                <ClickableHouse
                  key={i.toString()}
                  fileName={filename}
                  base64Image={base64Image}
                  cssGridOrder={cachedSessionImages.length - i}
                />
              ))}
            {isShowingAllImages &&
              allImages.map(({ filename, base64Image }, i) => (
                <ClickableHouse
                  key={i.toString()}
                  fileName={filename}
                  base64Image={base64Image}
                  cssGridOrder={cachedFeedImages.length - i}
                />
              ))}
          </div>
        </div>
        {!isShowingAllImages && cachedSessionImages.length === 0 && (
          <div className="Block Block--centeredStart">
            <button
              className="Button--start"
              onClick={() => navigate("/upload")}
            >
              <img src={AddCircle} alt={""} />
            </button>
            <div>
              <p className="Block-text Block-text--feed">
                Nothing to show.
                <br />
              </p>
              <p className="Block-text Block-text--feed --small">
                Press the button to create
                <br />
                your first wooden building!
              </p>
            </div>
          </div>
        )}
      </section>
    </main>
  );
}

export default Profile;
