// TranslationContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";

interface TranslationContextType {
    selectedLanguage: string;
    setSelectedLanguage: (language: string) => void;
    translate: (key: string) => string;
}

const TranslationContext = createContext<TranslationContextType | undefined>(undefined);

interface TranslationProviderProps {
    children: React.ReactNode;
}

type TranslationsData = {
    [key: string]: string;
};

const TranslationProvider: React.FC<TranslationProviderProps> = ({ children }) => {
    const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default to English
    const [translations, setTranslations] = useState<TranslationsData>({});

    useEffect(() => {
        // Load translations from file
        const loadTranslations = async () => {
            let translationsData: TranslationsData;

            switch (selectedLanguage) {
                case 'en':
                    translationsData = (await import('../translations/en.json')).default;
                    break;
                default:
                    translationsData = (await import('../translations/en.json')).default;
            }

            setTranslations(translationsData);
        };

        loadTranslations();
    }, [selectedLanguage]);

    // Translate function that will be passed to components
    const translate = (key: string) => {
        return translations[key] || key;
    };

    return (
        <TranslationContext.Provider value={{ selectedLanguage, setSelectedLanguage, translate }}>
            {children}
        </TranslationContext.Provider>
    );
};

// Custom hook to get the selected language context
const useTranslation = (): TranslationContextType => {
    const context = useContext(TranslationContext);
    if (!context) {
        throw new Error("useTranslation must be used within a TranslationProvider");
    }
    return context;
};

export { TranslationProvider, useTranslation };
