import React, { createContext, useContext, ReactNode, useState } from "react";

type AlertType = "success" | "error" | "info";

interface Alert {
  message: string;
  type: AlertType;
}

/* Component */
interface AlertProps {
  message: string;
  type: AlertType;
}

const Alert: React.FC<AlertProps> = ({ message, type }) => {
  return <div className={`Alert --${type}`}>{message}</div>;
};

/* Context */
interface AlertContextProps {
  showAlert: (message: string, type: AlertType) => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

/* Hook */
export function useAlert() {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
}

/* Provider */
interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [currentAlert, setCurrentAlert] = useState<Alert | null>(null);

  const showAlert = (message: string, type: AlertType) => {
    setCurrentAlert({ message, type });
    setTimeout(() => {
      setCurrentAlert(null);
    }, 3000); // Auto dismiss after 5 seconds
  };

  const value = {
    showAlert,
  };

  return (
    <AlertContext.Provider value={value}>
      {currentAlert && (
        <Alert message={currentAlert.message} type={currentAlert.type} />
      )}
      {children}
    </AlertContext.Provider>
  );
};
