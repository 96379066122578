import Layout from "../components/Layout";

function Contact() {
  return (
    <Layout>
      <h1>Contact page</h1>
      <button>Contact us</button>
    </Layout>
  );
}

export default Contact;
